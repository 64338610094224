import { createRouter, createWebHistory } from "vue-router";
import { projectAuth } from "../firebase/config";
import configSetup from "../setup";

const { owner } = configSetup();

const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser;
  if (!user || user.uid !== owner) {
    next({ name: "Login" });
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/auth/Login.vue"),
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("../views/auth/ForgotPassword.vue"),
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import("../views/auth/Signup.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/services/tinting",
    name: "Tinting",
    component: () => import("../views/Tint-Services.vue"),
  },
  {
    path: "/booking",
    name: "Booking",
    component: () => import("../views/Booking.vue"),
  },
  {
    path: "/tint-options",
    name: "TintOptions",
    component: () => import("../views/TintOptions.vue"),
  },
  {
    path: "/after-care",
    name: "AfterCare",
    component: () => import("../views/AfterCare.vue"),
  },
  {
    path: "/404",
    name: "page404",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
  },
  {
    path: "/tac",
    name: "TermsService",
    component: () => import("../views/TermsService.vue"),
  },
  {
    path: "/dashboard/image-loader",
    name: "ImageLoader",
    component: () => import("../views/Dashboard/ImageLoader.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/dashboard/warranty-creator",
    name: "WarrantyCreator",
    component: () => import("../views/Dashboard/WarrantyCreator.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: () => import("../views/Gallery.vue"),
  },
  {
    path: "/my-warranty",
    name: "MyWarranty",
    component: () => import("../views/MyWarranty.vue"),
  },
  {
    path: "/dashboard/warranty-search",
    name: "WarrantyList",
    component: () => import("../views/Dashboard/WarrantyList.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/dashboard/gallery-edit",
    name: "GalleryEdit",
    component: () => import("../views/Dashboard/GalleryEdit.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/dashboard/settings",
    name: "Settings",
    component: () => import("../views/Dashboard/Settings.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/dashboard/service-prices",
    name: "ServicePrices",
    component: () => import("../views/Dashboard/ServicePrices.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/dashboard/booking-requests",
    name: "BookingRequests",
    component: () => import("../views/Dashboard/BookingRequests.vue"),
    beforeEnter: requireAuth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0 };
  },
});

export default router;
