const configSetup = () => {
  // Use this config file to generate client info

  // Access
  const demo = true;
  const owner = "Be6mNSOxOHfQlE9DXcVl1vMQXji1";
  const companyProfileName = "s3Demo";

  const company = "Tint Nation";

  const logo =
    "https://firebasestorage.googleapis.com/v0/b/s3-tint.appspot.com/o/S3%20Assets%2Fs3mobile.png?alt=media&token=a0912cf4-c7b7-4b47-bf5f-abbd3cb0ce07";

  const url = "s3tint.com";

  const address = "123 Fake Stree Blvd";
  const state = "California";
  const city = "Las Vegas";
  const country = "United States";
  const nation = "American";
  const mapLink =
    "https://www.google.com/maps/dir//51.1799491,-114.1211909/@51.1799282,-114.1912305,12z";
  const geo = "LA";

  const description =
    "We are arguably Las Vegas' most professional team of window tinters and we are at your service.";

  const number = "123-456-7890";
  const tel = "1234567890";

  const email = "demo@s3tint.com";

  const hours = [
    "Mon | 10AM - 8PM",
    "Tues | 10AM - 8PM",
    "Wed | 10AM - 8PM",
    "Thurs | 10AM - 8PM",
    "Fri | 10AM - 8PM",
    "Sat - Sun | Closed",
  ];

  const reviews = "elfsight-app-7c39fdbf-5a63-497b-bc02-ce91407631d7";

  // Booking

  const booking = "https://s3tint.simplybook.me/v2/#book";

  // Social

  const facebook = "www.facebook.com/s3tint";
  const instagram = "www.instagram.com/s3tint";
  const linkedIn = "";
  const youTube = "";

  // brand color

  const textbrand50 = `text-blue-50`;
  const textbrand100 = `text-blue-100`;
  const textbrand200 = `text-blue-200`;
  const textbrand300 = `text-blue-300`;
  const textbrand400 = `text-blue-400`;
  const textbrand500 = `text-blue-500`;
  const textbrand600 = `text-blue-600`;
  const textbrand700 = `text-blue-700`;
  const textbrand800 = `text-blue-800`;
  const textbrand900 = `text-blue-900`;

  const bgbrand50 = `bg-blue-50`;
  const bgbrand100 = `bg-blue-100`;
  const bgbrand200 = `bg-blue-200`;
  const bgbrand300 = `bg-blue-300`;
  const bgbrand400 = `bg-blue-400`;
  const bgbrand500 = `bg-blue-500`;
  const bgbrand600 = `bg-blue-600`;
  const bgbrand700 = `bg-blue-700`;
  const bgbrand800 = `bg-blue-800`;
  const bgbrand900 = `bg-blue-900`;

  const borderbrand50 = `border-blue-50`;
  const borderbrand100 = `border-blue-100`;
  const borderbrand200 = `border-blue-200`;
  const borderbrand300 = `border-blue-300`;
  const borderbrand400 = `border-blue-400`;
  const borderbrand500 = `border-blue-500`;
  const borderbrand600 = `border-blue-600`;
  const borderbrand700 = `border-blue-700`;
  const borderbrand800 = `border-blue-800`;
  const borderbrand900 = `border-blue-900`;

  const ringbrand600 = "ring-blue-600";
  const ringbrand500 = "ring-blue-500";

  const theme = `dark`;

  // homepage company image

  const homeImg = "";

  // db variables

  const dbGallery = "s3DemoGallery";
  const dbTintingServices = "s3DemoTintingServices";
  const dbWarranty = "s3DemoWarranty";

  // Other Services

  const lightEnabled = true;
  const flatGlassEnabled = true;

  return {
    demo,
    companyProfileName,
    owner,
    dbGallery,
    dbWarranty,
    dbTintingServices,
    theme,
    company,
    address,
    number,
    tel,
    mapLink,
    email,
    hours,
    reviews,
    booking,
    geo,
    logo,
    facebook,
    instagram,
    linkedIn,
    youTube,
    homeImg,
    url,
    state,
    city,
    country,
    nation,
    description,
    textbrand50,
    textbrand100,
    textbrand200,
    textbrand300,
    textbrand400,
    textbrand500,
    textbrand600,
    textbrand700,
    textbrand800,
    textbrand900,
    bgbrand50,
    bgbrand100,
    bgbrand200,
    bgbrand300,
    bgbrand400,
    bgbrand500,
    bgbrand600,
    bgbrand700,
    bgbrand800,
    bgbrand900,
    borderbrand50,
    borderbrand100,
    borderbrand200,
    borderbrand300,
    borderbrand400,
    borderbrand500,
    borderbrand600,
    borderbrand700,
    borderbrand800,
    borderbrand900,
    ringbrand600,
    ringbrand500,
    lightEnabled,
    flatGlassEnabled,
  };
};

export default configSetup;
