<template>
  <div class="fixed inset-x-0 bottom-0 z-10">
    <div class="bg-yellow-600">
      <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between flex-wrap">
          <div class="w-0 flex-1 flex items-center">
            <p class="ml-3 font-medium text-white truncate">
              <span class="md:hidden uppercase text-xl">
                This is a demo site
              </span>
              <span class="hidden md:inline uppercase text-xl">
                This is a demo site
              </span>
            </p>
          </div>
          <div class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
            <a href="https://solutions.s3tint.com" target="_blank" class="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-yellow-600 bg-white hover:bg-yellow-50">
              Learn more about this demo site
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
}
</script>