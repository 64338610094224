import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";
import "firebase/functions";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyClgVyptAl5AZzHWLOLIr-8fnnhE0l4zf0",
  authDomain: "s3-tint.firebaseapp.com",
  projectId: "s3-tint",
  storageBucket: "s3-tint.appspot.com",
  messagingSenderId: "866830665113",
  appId: "1:866830665113:web:33a2cc81bfbb3cfaa37f08",
  measurementId: "G-69LMM2FZVV",
};

// init firebase
firebase.initializeApp(firebaseConfig);

// init service
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const projectStorage = firebase.storage();
const functions = firebase.functions();

// timestap
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

//init analytics

const analytics = firebase.analytics();

export {
  projectFirestore,
  projectAuth,
  projectStorage,
  timestamp,
  analytics,
  functions,
};
