import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";
import { projectAuth } from "./firebase/config";
import VueGtag from "vue-gtag";

let app;

projectAuth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App)
      .use(router)
      .use(VueGtag, {
        config: { id: "G-V22442SP2B" },
        enabled: false,
      })
      .mount("#app");
  }
});
