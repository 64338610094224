<template>
  <DemoBanner/>
  <Navbar />
  <div>
  <router-view/>
  </div>
  <Footer/>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'
import DemoBanner from './components/DemoBanner.vue'
export default {
  components: {Navbar, Footer, DemoBanner}
}
</script>
