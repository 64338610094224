<template>
  <div :class="theme">
  
    <header class="bg-gray-900">
    <nav class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
       <div class="py-4 flex flex-wrap justify-center  md:justify-between space-x-6 border-b border-gray-700">
        <a v-for="link in navigationContact" :key="link.name" :href="link.link" :target="link.target" class="text-base font-medium text-gray-50">
          {{ link.name }}
        </a>
      </div>
<!-- Center nav       -->
      <div class="w-full py-6 md:flex items-center justify-between border-b-2 bg-gray-50 p-3 rounded dark:bg-gray-900 " :class="borderbrand500" >
        <div class="flex items-center mb-5 md:mb-0">
          <router-link :to="{name: 'Home'}">
            <span class="sr-only">{{company}}</span>
            <img class="h-16 w-auto" :src="logo" :alt="company" />
          </router-link>
        </div>

<!-- Auth buttons  -->
        <div v-if="user" class="md:flex md:items-center md:justify-end md:flex-1 lg:w-0 md:space-x-2">
            <div class="mb-4 md:mb-0">
            <router-link :to="{ name: 'MyWarranty' }"  class="whitespace-nowrap rounded text-base font-medium px-2 py-1 text-gray-50 dark:hover:text-gray-50 hover:text-gray-900" :class="bgbrand500">
            My Warranty
            </router-link>
            </div>          
            <div v-if="!user">
              <router-link :to="{ name: 'Login' }"  class="whitespace-nowrap text-base font-medium text-gray-500">
              Admin
              </router-link>
              
            </div>
          <div v-else class=" flex text-gray-200 border border-gray-200 border-gray-700 p-1 rounded">
              <router-link :to="{ name: 'ImageLoader' }" class="mx-2 whitespace-nowrap text-base font-medium " :class="textbrand500"> 
                Dashboard
              </router-link>
               <router-link :to="{ name: 'Signup' }" class="hidden ml-1 whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-200"> 
                Register User
               </router-link>
               <div class="px-2"> | </div>
               <router-link :to="{ name: 'Login' }" @click="handleClick" class="ml-1 whitespace-nowrap text-base font-medium dark:text-gray-500 dark:hover:text-gray-200"> 
                Log Out
              </router-link>
          </div>    

        </div>
        <div v-else class="flex md:items-center md:justify-end md:flex-1 lg:w-0 md:space-x-2">
            <div class="mb-4 md:mb-0">
            <router-link :to="{ name: 'MyWarranty' }"  class="whitespace-nowrap rounded text-base font-medium px-2 py-1 text-gray-50 dark:hover:text-gray-50 hover:text-gray-900" :class="bgbrand500">
            My Warranty
            </router-link>
            </div>          
            <div v-if="!user" class="ml-5">
              <router-link :to="{ name: 'Login' }"  class="whitespace-nowrap text-base font-medium text-gray-500">
              Admin
              </router-link>
              
            </div>  

        </div>
      </div>
      <div class="py-4 flex flex-wrap justify-center space-x-2">
        <router-link v-for="link in navigation" :key="link.name" :href="link.href" :to="{ name: link.link }" class="text-base font-medium text-gray-50 px-1 rounded" :class="`hover:${bgbrand500}`">
          {{ link.name }}
        </router-link>
      </div>
    </nav>
  </header>
  </div> 
</template>

<script>
import useLogout from '@/composables/useLogout';
import { useRouter } from 'vue-router';
import getUser from '@/composables/getUser'
import configSetup from '@/setup.js'

const navigation = [
  { name: 'Home', link: 'Home' },
  { name: 'Tint Quote', link: 'Tinting' },
  { name: 'Gallery', link: 'Gallery' },
  { name: 'Booking', link: 'Booking' },
]




export default {
  components: {
  },
  setup() {
    

    const {error, logout, isPending} = useLogout()
    const router = useRouter()
    const { user } = getUser()
    const {
      borderbrand500,
      textbrand500,
      textbrand200,
      bgbrand500,
      theme,
      company, 
      address, 
      number, 
      tel, 
      mapLink, 
      logo} = configSetup()

    const navigationContact = [
      { name: number, link: tel },
      { name: address, link: mapLink, target: '_blank' },
    ]

    const handleClick = async () => {
      await logout()

      if (!error.value) {
        console.log('User logged out')
        router.push({name: 'Login'})
      }

    }

    return {
      error, 
      logout, 
      isPending,
      handleClick,
      user,
      navigation,
      navigationContact,
      borderbrand500,
      textbrand500,
      textbrand200,
      bgbrand500,
      theme,
      company, 
      logo
    }
  },
}
</script>